/* 폰트 */
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 100;
  src: url('/src/common/font/Pretendard-Thin.woff') format('woff'), url('/src/common/font/Pretendard-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 200;
  src: url('/src/common/font/Pretendard-ExtraLight.woff') format('woff'), url('/src/common/font/Pretendard-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('/src/common/font/Pretendard-Light.woff') format('woff'), url('/src/common/font/Pretendard-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('/src/common/font/Pretendard-Regular.woff') format('woff'), url('/src/common/font/Pretendard-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('/src/common/font/Pretendard-Medium.woff') format('woff'), url('/src/common/font/Pretendard-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('/src/common/font/Pretendard-SemiBold.woff') format('woff'), url('/src/common/font/Pretendard-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  src: url('/src/common/font/Pretendard-Bold.woff') format('woff'), url('/src/common/font/Pretendard-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 800;
  src: url('/src/common/font/Pretendard-ExtraBold.woff') format('woff'), url('/src/common/font/Pretendard-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  src: url('/src/common/font/Pretendard-Black.woff') format('woff'), url('/src/common/font/Pretendard-Black.woff2') format('woff2');
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
